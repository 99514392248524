<!-- Name -->
<script lang="ts">
export default {
  name: 'app-brand-card-vehicle'
}
</script>

<script lang="ts" setup>
import { useBrandsStore } from '~/store/brands';
import { useBrandsInfoStore } from '~/store/brandsInfo';
import { useFavouritesStore } from '~/store/favourites';
import { useComparatorStore } from '~/store/comparator';

import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();
const { isAuthenticated } = useSanctumAuth();

const favouriteModal = resolveComponent('ui/modals/app-favourite');
const comparatorModal = resolveComponent('ui/modals/app-comparator');
const comparatorMaxSizeModal = resolveComponent('ui/modals/app-comparator-max');
const loginModal = resolveComponent('ui/modals/app-login');

const { getBrand } = useBrandsInfoStore();
const { showHideModal } = modalStore;

type Detail = 'stock-vn' | 'electricos';
const route = useRoute();
const router = useRouter();
const { setVehiculoReserva } = useBrandsStore();
const brand = getBrand(route.params.marca);
const firstLoad=ref(false);
const favouritesStore= useFavouritesStore();
const { favouritesBasic } = storeToRefs(favouritesStore);
const comparatorStore= useComparatorStore();
const { comparedBasic } = storeToRefs(comparatorStore);
const existFav= ref(false);
const existComp= ref(false);


const props = defineProps({
  vehicle: {
    type: Object
  },
  detailType: {
    type: String as PropType<Detail>,
    default: 'stock-vn'
  },
  slug: {
    type: String
  },
  type: {
    type: String,
    required: false
  },
  isFavouritePage:{
    type: Boolean,
    default: false
  }
});

const showModalFavourite = () => {
  const payload = {
    showModal: true,
    component: favouriteModal,
    size: "full-mobile",
    props: {
        isBrand: true,
        brand: route.params.marca
    }
  };

  showHideModal(payload);
};

const showModalComparator = () => {
  const payload = {
    showModal: true,
    component: comparatorModal,
    size: "full-mobile",
    props: {
        isBrand: true,
        brand: route.params.marca
    }
  };

  showHideModal(payload);
};

const showModalComparatorMaxSize = () => {
  const payload = {
    showModal: true,
    component: comparatorMaxSizeModal,
    size: "full-mobile",
    props: {
        isBrand: true
    }
  };

  showHideModal(payload);
};

const showModalLogin= () => {
  const payload = {
  showModal: true,
  component: loginModal,
  size: "full-mobile",
  props: {
      isBrand: true
  }
  };
  showHideModal(payload);

};

const isMenuOpen = ref(false);


const showHideMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const type = computed(()=>{
  return props.type==='km0' ? 'vo' : props.type;
});
const goToReservation = () => {
  setVehiculoReserva(props.vehicle);
  router.push({ path: `/${route.params.marca}/reservar/${type.value}/${props.vehicle.slug}/`});
};

const addFavourite=async ()=>{
  if(isAuthenticated.value){
  await favouritesStore.add(props.vehicle.id_vehiculos,type.value,route.params.marca ?? '',props.isFavouritePage ?? false);
  showModalFavourite();
  }else{
    showModalLogin();
  }
};

const removeFavourite=async ()=>{
  await favouritesStore.remove(props.vehicle.id_vehiculos,type.value,route.params.marca ?? '',props.isFavouritePage ?? false);
};

const addCompared=async ()=>{
  if(comparedBasic.value.length>=4){
    showModalComparatorMaxSize();
  }else{
    await comparatorStore.add(props.vehicle.id_vehiculos,props.type,route.params.marca ?? '',props.isComparatorPage ?? false);
    showModalComparator();
  }

};

const removeCompared=async ()=>{
  await comparatorStore.remove(props.vehicle.id_vehiculos,props.type,route.params.marca ?? '',props.isComparatorPage ?? false);
};


onMounted(()=>{
  if(favouritesBasic.value.includes(props.vehicle.id_vehiculos)){
    existFav.value=true;
  }

  if(comparedBasic.value.includes(props.vehicle.id_vehiculos)){
    existComp.value=true;
  }

  watch(favouritesBasic,()=>{
    if(favouritesBasic.value.includes(props.vehicle.id_vehiculos)){
      existFav.value=true;
    }else{
      existFav.value=false;
    }
  });

  watch(comparedBasic,()=>{
    if(comparedBasic.value.includes(props.vehicle.id_vehiculos)){
      existComp.value=true;
    }else{
      existComp.value=false;
    }
  });

});

const isCarReserved = computed(()=> {
  const ids = [2, 3, 6, 7];
  return ids.includes(props.vehicle.id_estados);
});

const isCarSold = computed(()=> {
  const ids = [4];
  return ids.includes(props.vehicle.id_estados);
});



</script>

<template lang="pug">
article.app-brand-card-vehicle(:class="[{'reserved':isCarReserved},{'sold':isCarSold} ]")
  .app-brand-card-vehicle__menu(:class="{'show': isMenuOpen}")
    ul
      li(@click="isAuthenticated ? (existFav ? removeFavourite(): addFavourite()): showModalLogin()")
        span.icon-heart
        span {{ existFav ? 'Eliminar de favoritos':'Añadir a favoritos'}}
      li(@click="isAuthenticated ? (existComp ? removeCompared(): addCompared()): showModalLogin()") 
        span.icon-arrow-filter
        span {{ existComp ? 'Eliminar del comparador':'Añadir al comparador'}}
  .app-brand-card-vehicle__header
    nuxt-link(:to="`/${slug}/`")
      img.img-fluid(:src="vehicle?.imagenes[0]", alt="alt")
  article.app-brand-card-vehicle__body
    //- button.btn-round
    button.btn-round(@click="showHideMenu", :class="{'active': isMenuOpen}")
      span.icon-add
    header
      .details.d-flex.justify-content-between
        .model-version
          p.h3.text--brand-primary.model.d-block {{ vehicle?.marca_modelo }}
          p.h4.text--brand-primary.version {{ vehicle?.version }}

      .specs
        ul.d-flex.align-items-center
          li.spec(v-if="type!=='vn' && type != 'moto-vn'")
            span {{vehicle.kilometros}} Km
          li.spec(v-if="!!vehicle.combustible")
            span {{vehicle.combustible}}
          li.spec(v-if="!!vehicle.transmision && vehicle.transmision != 0")
            span {{vehicle.transmision}}
          li.spec(v-if="!!vehicle.potencia")
            span {{ vehicle.potencia }} CV
      //-h2.location Vehículo ubicado en: {{ vehicle.datos_sede.provincia }}
    hr
    article.price
      .d-flex.justify-content-between.align-items-center(v-if="(brand.tipo_precio == 'pvp' || brand.tipo_precio == 'all') && vehicle.precio && vehicle.precio>0")
        p.h3.text--brand-primary.price__amount
          small AL CONTADO
        span.text--brand-primary {{vehicle.precio}}€
      hr(v-if="brand.tipo_precio == 'all' && vehicle.precio_financiado && vehicle.precio_financiado>0")
      .d-flex.justify-content-between.align-items-center(v-if="(brand.tipo_precio == 'pvf' || brand.tipo_precio == 'all' ) && vehicle.precio_financiado && vehicle.precio_financiado>0")
        p.h3.text--brand-primary.price__financing
          small FINANCIADO
        span.text--brand-primary {{vehicle.precio_financiado}}€
    hr
    .app-brand-card-vehicle__footer
      .d-flex.justify-content-center.align-items-center.gap-2
        button.btn-brand.btn-brand--primary(@click="goToReservation" v-if="type !== 'moto-vo' && type !== 'moto-vn'")
          span RESERVA
          //- span.icon-bag-cross
        nuxt-link(:to="`/${slug}/`").btn-brand.btn-brand--outlined-primary
          span VER FICHA


</template>

<style lang="scss">
@import 'index';
</style>